.hidden {
  display: none;
}
.tp-widget-loader,
.tp-widget-fallback {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
#fallback-logo {
  display: none;
}
.no-svg #fallback-logo {
  display: inline-block;
}
.tp-widget-wrapper {
  height: 100%;
  max-width: 750px;
  position: relative;
  margin: 0 auto;
}
.tp-stars .tp-star__canvas,
.tp-stars .tp-star__canvas--half {
  fill: #dcdce6;
}
.tp-stars .tp-star__shape {
  fill: #fff;
}
.tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas,
.tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas--half {
  fill: #ff3722;
}
.tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas,
.tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
  fill: #ff8622;
}
.tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas,
.tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
  fill: #ffce00;
}
.tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
.tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #73cf11;
}
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas,
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-stars--1--half .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
  fill: #ff3722;
}
.tp-stars--2--half .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
  fill: #ff8622;
}
.tp-stars--3--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #ffce00;
}
.tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
.tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-stars--4--half .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-widget-loader,
.tp-widget-fallback {
  max-width: 50%;
  text-align: center;
  width: 400vh;
}
.tp-widget-loader svg,
.tp-widget-fallback svg {
  width: 100%;
}
@media screen and (min-width: 400px) {
  .tp-widget-loader,
  .tp-widget-fallback {
    max-width: 200px;
  }
}
.tp-widget-loader--loaded {
  opacity: 0;
}
.bold-underline {
  border-bottom: 1px solid rgba(25, 25, 25, 0.6);
}
.bold-underline:hover {
  border-color: #191919;
}
.dark {
  color: #fff;
}
.dark a {
  color: #fff;
}
.dark .bold-underline:not(.bold-underline--single-theme) {
  border-color: rgba(255, 255, 255, 0.6);
}
.dark .bold-underline:not(.bold-underline--single-theme):hover {
  border-color: #fff;
}
.dark .tp-logo__text {
  fill: #fff;
}
.dark .tp-widget-loader .tp-logo__text {
  fill: #191919;
}
svg {
  display: block;
  width: 100%;
}
#profile-link {
  color: #191919;
  text-decoration: none;
}
.tp-widget-wrapper {
  max-width: 255px;
}
.tp-widget-wrapper--placeholder {
  display: none;
}
.tp-widget-logo {
  margin: 0 0 8px;
  width: 55%;
}
.tp-widget-stars {
  margin: 0 0 8px;
  width: 94%;
}
.tp-widget-businessinfo {
  font-size: 11px;
  line-height: 1.2;
}
.tp-widget-businessinfo__trustscore,
.tp-widget-businessinfo__total {
  text-transform: none;
}
@media screen and (min-width: 200px) {
  .tp-widget-logo {
    max-width: 140.25px;
  }
  .tp-widget-businessinfo {
    font-size: 14px;
    max-width: 255px;
  }
}
@media screen and (min-width: 235px) {
  .tp-widget-businessinfo__trustscore,
  .tp-widget-businessinfo__total {
    display: inline-block;
    vertical-align: middle;
  }
  .tp-widget-businessinfo__trustscore::after {
    content: "|";
    margin: 0 2px;
  }
}
#trust-score {
  font-weight: 700;
}
#businessEntity-numberOfReviews-total {
  font-weight: 700;
}
#reviews-summary {
  font-family: "Arial", "sans-serif" !important;
  font-size: 12px !important;
}
.preview-widget {
  -webkit-box-pack: center;
  justify-content: center;
  overflow: auto;
  padding: 32px 0;
  width: 100%;
}
.preview-widget,
.configuration__preview__inner {
  -webkit-box-flex: 1;
  display: -webkit-box;
  display: flex;
  flex-grow: 1;
}

.preview-widget-inner {
  width: 100%;
}

.preview-widget-background {
  -webkit-box-align: center;
  align-items: center;
  align-self: flex-start;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  display: none;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: 16px;
  position: relative;
}
.preview-widget .trustpilot-widget {
  -webkit-box-pack: center;
  display: -webkit-box;
  display: flex;
  justify-content: center;
  width: 100%;
}
.preview-widget {
  padding: 43px 0px 40px 0px;
}
.testimonial_margin{
  margin-bottom: 20px;
}
@media only screen and (max-width: 780px) {
  .preview-widget {
    padding: 20px 0px 32px 0px;
  }
  .testimonial_margin{
    margin-bottom: 0px !important;
  }
}
